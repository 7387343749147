<template lang="">
  <div>
   
    <div class="row">
      <div class="col-md-12">
        <div class="card dveb-white-card tab-card">
          <b-tabs v-model="tabActive" content-class="mt-3">
            <b-tab title="Varlık"><Varlik /></b-tab>
            <b-tab title="Konum"><Konum /></b-tab>
            <!-- <b-tab title="Taşınmaz" v-if="!tapuekle"><Tasinmaz /></b-tab> -->
            <!-- <b-tab title="Taşınmaz" v-if="tapuekle"><TapuEkle /></b-tab> -->
            <!-- <b-tab title="Taşınır"><Tasinir /></b-tab> -->
            <!-- <b-tab title="Dosyalar"><Dosyalar /></b-tab> -->
            <b-tab title="Belgeler"><Belgeler /></b-tab>
            <b-tab title="Görseller" ><Album /></b-tab>
            <!-- <b-tab title="Ziyaretler"><Ziyaretler /></b-tab> -->
            <!-- <b-tab title="Değerleme"><Degerleme /></b-tab> -->
            <b-tab title="Kayıtlar"><Kayitlar /></b-tab>
            <b-tab title="Müşteriler"><Musteriler /></b-tab>
            <!-- <b-tab title="Kira"><Kira /></b-tab> -->
            <!-- <b-tab title="Masraf"><Masraf /></b-tab> -->
            <!-- <b-tab title="Hatırlatmalar"><Hatirlatmalar /></b-tab> -->
            <!-- <b-tab title="İlgilenen Kişiler"><Kisiler /></b-tab> -->
          </b-tabs>
        </div>
      </div>
   
      <div class="notes-sidebar">
        <b-sidebar id="sidebar-right" right>
          <div
            class="card dveb-gray-card aside-gray-card"
            style="overflow-y: auto"
          >
            <div class="card-header">
              0 NOT
              <b-button variant="light ml-4" @click="noteModalShow = true"
                >NOT EKLE
              </b-button>
            </div>
            <div
              class="card mt-4 dveb-card-white p-4"
              v-for="(note, key) in notes"
              :key="key"
              style="border-radius: 0 !important"
            >
              <div>
                <h4>{{ note.writer }}</h4>
                <span class="text-bold">{{ note.date }}</span>
                <p class="mt-4">{{ note.note }}</p>
                <hr />
                <div class="row">
                  <b-button
                    variant="danger"
                    class="ml-auto mx-4"
                    @click="openDeleteHandler(key)"
                  >
                    Sil
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </b-sidebar>
        <b-sidebar id="sidebar-left" left>
          <div class="card dveb-gray-card aside-gray-card">
            <div class="card-header">ING</div>
            <div class="mt-4">
              <MapView class="map"  />
            </div>
            <div class="d-inline-flex mt-4">
              <div class="mx-auto d-inline-flex flex-column">
                <b-button
                  v-b-tooltip.hover
                  title="Varlık Sahibinin Aynı İldeki Varlıkları"
                  variant="light"
                >
                  <i class="flaticon-user"></i>
                  <span class="badge badge-secondary text-primary ml-4">1</span>
                </b-button>
                <p class="text-bold mt-4">Hatay, ANTAKYA</p>
              </div>
            </div>
            <div class="bg-dveb-light mt-4">
              <p class="text-bold">1 Tapu</p>
            </div>
            <div class="bg-dveb-light mt-4">
              <button class="btn btn-secondary btn-sm mr-4">
                Tapuları Listele
              </button>
              <button class="btn btn-xs" @click="openDeleteHandler">
                <i class="flaticon2-trash text-danger"></i>
                Sil
              </button>
            </div>
          </div>
        </b-sidebar>
      </div>
    </div>
    <b-modal v-model="noteModalShow" id="note-modal">
      <template #modal-header>
        <h4><i class="flaticon2-plus mr-4"></i> Not Ekle</h4>
      </template>
      <div class="form-group">
        <label for="note">Not</label>
        <textarea
          name=""
          v-model="newNote"
          id="note"
          rows="4"
          class="form-control"
        ></textarea>
      </div>
      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <b-button variant="light" @click="noteModalShow = false">
            Vazgeç
          </b-button>
          <b-button variant="success" @click="addNewNote" class="ml-4">
            Kaydet
          </b-button>
        </div>
      </template>
    </b-modal>
    <DeleteOperation />
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  name: "propertyDetail",
  components: {
    MapView: () => import("@/components/dashboard/MapView"),
    Belgeler: () => import("@/components/property/DetailTabs/Belgeler"),
    Varlik: () => import("@/components/property/DetailTabs/Varlik"),
    Konum: () => import("@/components/property/DetailTabs/Konum"),
    // Tasinmaz: () => import("@/components/property/DetailTabs/Tasinmaz"),
    // Tasinir: () => import("@/components/property/DetailTabs/Tasinir"),
    // Dosyalar: () => import("@/components/property/DetailTabs/Dosyalar"),
    Album: () => import("@/components/property/DetailTabs/Album"),
    // AlbumEdit: () => import("@/components/property/DetailTabs/AlbumEdit"),
    TapuEkle: () => import("@/components/property/DetailTabs/TapuEkle"),
    // Ziyaretler: () => import("@/components/property/DetailTabs/Ziyaretler"),
    // Degerleme: () => import("@/components/property/DetailTabs/Degerleme"),
    Kira: () => import("@/components/property/DetailTabs/Kira"),

    Masraf: () => import("@/components/property/DetailTabs/Masraf"),
    Hatirlatmalar: () =>
      import("@/components/property/DetailTabs/Hatirlatmalar"),
    Kisiler: () => import("@/components/property/DetailTabs/Kisiler"),
    Kayitlar:() => import("@/components/property/DetailTabs/Kayitlar"),
    Musteriler:() => import("@/components/property/DetailTabs/Musteriler"),
  },
  data() {
    return {
      noteModalShow: false,
      notes: [
        {
          writer: "ASUMAN KOCABAŞ",
          date: "29-05-2023 14:32",
          note: "29.05.2023 TARİHİNDE ERDAL BEY'DEN GELEN BİLGİYE İSTİNADEN YAYINDAN KALDIRILDI....",
        },
        {
          writer: "ASUMAN KOCABAŞ",
          date: "29-05-2023 14:32",
          note: "29.05.2023 TARİHİNDE ERDAL BEY'DEN GELEN BİLGİYE İSTİNADEN YAYINDAN KALDIRILDI....",
        },
        {
          writer: "ASUMAN KOCABAŞ",
          date: "29-05-2023 14:32",
          note: "29.05.2023 TARİHİNDE ERDAL BEY'DEN GELEN BİLGİYE İSTİNADEN YAYINDAN KALDIRILDI....",
        },
        {
          writer: "ASUMAN KOCABAŞ",
          date: "29-05-2023 14:32",
          note: "29.05.2023 TARİHİNDE ERDAL BEY'DEN GELEN BİLGİYE İSTİNADEN YAYINDAN KALDIRILDI....",
        },
        {
          writer: "ASUMAN KOCABAŞ",
          date: "29-05-2023 14:32",
          note: "29.05.2023 TARİHİNDE ERDAL BEY'DEN GELEN BİLGİYE İSTİNADEN YAYINDAN KALDIRILDI....",
        },
        {
          writer: "ASUMAN KOCABAŞ",
          date: "29-05-2023 14:32",
          note: "29.05.2023 TARİHİNDE ERDAL BEY'DEN GELEN BİLGİYE İSTİNADEN YAYINDAN KALDIRILDI....",
        },
        {
          writer: "ASUMAN KOCABAŞ",
          date: "29-05-2023 14:32",
          note: "29.05.2023 TARİHİNDE ERDAL BEY'DEN GELEN BİLGİYE İSTİNADEN YAYINDAN KALDIRILDI....",
        },
        {
          writer: "ASUMAN KOCABAŞ",
          date: "29-05-2023 14:32",
          note: "29.05.2023 TARİHİNDE ERDAL BEY'DEN GELEN BİLGİYE İSTİNADEN YAYINDAN KALDIRILDI....",
        },
        {
          writer: "ASUMAN KOCABAŞ",
          date: "29-05-2023 14:32",
          note: "29.05.2023 TARİHİNDE ERDAL BEY'DEN GELEN BİLGİYE İSTİNADEN YAYINDAN KALDIRILDI....",
        },
        {
          writer: "r KOCABAŞ",
          date: "29-05-2023 14:32",
          note: "29.05.2023 TARİHİNDE ERDAL BEY'DEN GELEN BİLGİYE İSTİNADEN YAYINDAN KALDIRILDI....",
        },
        {
          writer: "a KOCABAŞ",
          date: "29-05-2023 14:32",
          note: "29.05.2023 TARİHİNDE ERDAL BEY'DEN GELEN BİLGİYE İSTİNADEN YAYINDAN KALDIRILDI....",
        },
        {
          writer: "x KOCABAŞ",
          date: "29-05-2023 14:32",
          note: "29.05.2023 TARİHİNDE ERDAL BEY'DEN GELEN BİLGİYE İSTİNADEN YAYINDAN KALDIRILDI....",
        },
        {
          writer: "z KOCABAŞ",
          date: "29-05-2023 14:32",
          note: "29.05.2023 TARİHİNDE ERDAL BEY'DEN GELEN BİLGİYE İSTİNADEN YAYINDAN KALDIRILDI....",
        },
      ],
      newNote: "",
      badges: [1, 2, 3],
      badgesEnum: ["green", "yellow", "red"],
      tabNames: [{ name: "Varlık", component: <EmlakBeyan /> }],
      albumEdit: false,
      tabActive: 0,
      tapuekle  : false,
    };
  },
  mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Varlık Detay", route: "/properties/find/detail/1/" },
      ]);
    },
  methods: {
    addNewNote() {
      let newNote = {
        writer: "Burak Kaşıkçı",
        note: this.newNote,
        date:
          new Date().toLocaleDateString("tr-TR") +
          " " +
          new Date().getHours() +
          ":" +
          new Date().getMinutes(),
      };
      this.notes = [newNote, ...this.notes];
      this.newNote = "";
      this.noteModalShow = false;
    },
    openDeleteHandler(key) {
      this.$bvModal.show("modal-deleteOperation");
      let index = this.notes.indexOf(key);
      this.notes.splice(index, 1);
    },
  

  },

  created() {
    
    this.$watch((this.tapuekle = this.$route.name === "tapuekle" ? true : false));
  },
  watch: {
    $route(to, from) {
      this.albumEdit = this.$route.params.albumid ? true : false;
      this.tapuekle = this.$route.name === "tapuekle" ? true : false;
    },
    tabActive: function () {
      if (this.$route.params.albumid || this.$route.name === "tapuekle") {
        const currentParams = this.$router.currentRoute.params;
        this.$router.push(`/properties/find/detail/${currentParams.id}/`);
      }
    },
  },
  mounted(){
    window.addEventListener('resize', () => {
      this.onResize
  })
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },

};
</script>
<style lang="scss" scoped>
.card {
  width: 100%;
}
.badges-area {
  display: flex;
  gap: 1rem;
  p {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: $dveb-primary !important;
  }
  .badges {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    .badge {
      display: inline-block;
      width: 1rem;
      height: 1rem;

      border-radius: 50%;
      padding: 0 !important;
      &.green {
        background-color: #44b6ae;
      }
      &.yellow {
        background-color: #ffac00;
      }
      &.red {
        background-color: red;
      }
    }
  }
  span {
    font-size: 1rem;
  }
}
.title-card {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.map {
  height: 200px;
}
</style>
